@import "variables";

.Actus-slider {
  // @media (m-width: 1600px) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  z-index: 0;
  // }
  ul {
    justify-content: center;
    align-items: center;
  }

  li {
    justify-content: space-evenly;
  }

  .react-multiple-carousel__arrow::before {
    color: $orange;
  }

  .react-multiple-carousel__arrow,
  .react-multiple-carousel__arrow:hover {
    background-color: transparent;
  }

  .react-multiple-carousel__arrow--left {
    bottom: 25px;
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    bottom: 25px;
    right: 0;
  }
}

.react-multi-carousel-item {
  // min-height: 410px;
  height: 100%;
  width: 100%;

  .card-actus {
    height: 100%;
    min-height: 400px;
    width: 100%;

    .card-title{
      font-size: 1rem;
      text-align: left;
    }
  }


  @media (max-width: 991px) {
    .card-actus {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 250px;
      border-radius: 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    .card-actus {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 300px;
      border-radius: 0;
    }
  }

  @media (min-width: 1300px) {
    .card-actus {
      display: flex;
      flex-direction: column;
      width: 90%;
      height: 350px;
      border-radius: 0;
    }
  }

  .flexible {
    flex: 1;
  }
  .MuiCardActions-root {
    justify-content: center;
    bottom: 0;
  }

}
