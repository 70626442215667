@import "variables";
.evenements {
  img {
    max-width: 100%;
    height: auto;
  }
}
.list-evenements {
  display: flex;
  gap: 5px;
  padding: 5px;
  border: 1px solid $grisclair;
  margin-bottom: 20px;

  .evenement-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
