@import "variables";

.Homepagewrapper {
  display: flex;
  padding-top: $startLayout;
  position: relative;
  max-width: 100%;
}

.Wrapper {
  display: flex;
  position: relative;
  @media (min-width: 1350px) {
    padding-left: 120px;
    padding-right: 120px;
    gap: 75px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}


.WrapperHomepage {
  display: flex;
  position: relative;
  @media (min-width: 1350px) {
    gap: 75px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.topLayout{
  padding-top: $startLayout;
  position: relative;
}

.topLayoutHomepage{
  padding-top: $startLayoutHomepage;
  position: relative;
}

.Main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  // padding: 1rem;
  min-height: calc(100vh - #{$startLayout*2});
  overflow-x: hidden;
}
