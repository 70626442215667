@import "variables";

.PageNotFound{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.PageNotFoundMessage{
  padding-top: $startLayoutHomepage + 20px;
  text-align: center;
}