@import "variables";

.filiere-slider {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  z-index: 0;
  ul {
    justify-content: center;
    align-items: center;
  }

  li {
    justify-content: space-evenly;
    list-style: none;
  }

  li:hover{
    transform: scale(1.01);
    transition: 0.3s;
  }

  .react-multiple-carousel__arrow--left {
    top: 205px;
    left: -15px;
  }

  .react-multiple-carousel__arrow--right {
    top: 205px;
    right: -15px;
  }
}

.react-multiple-carousel__arrow::before {
  color: $orange;
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
  background-color: transparent;
}

.react-multi-carousel-item {
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    border-radius: 0;
  }

  @media (max-width: 991px) {
    .card-filieres {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 320px;
      border-radius: 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    .card-filieres {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 320px;
      border-radius: 0;
    }
  }


  @media (min-width: 1300px) {
    .card-filieres {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 320px;
      border-radius: 0;
    }
  }


  .flexible {
    flex: 1;
  }
  .MuiCardActions-root {
    justify-content: center;
    bottom: 0;
  }
}
