@import "variables";
.fond-documentaire {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  h5 {
    text-align: left;
  }
}

.card-documents {
  width: 19%;
  min-width: 300px;
  margin: 0.5em 0;
}

.card-documents.vert {
  h5 {
    color: $vert;
  }
}

.card-documents.orange {
  h5 {
    color: $orange;
  }
}

.card-documents.bleuciel {
  h5 {
    color: $bleuciel;
  }
}

.card-documents.bleuvert {
  h5 {
    color: $bleuvert;
  }
}

.card-documents.bleumarine {
  h5 {
    color: $bleumarine;
  }
}
