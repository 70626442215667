@import "variables";

.paragraph--type--texte-fond-gris {
  background-color: #f4f4f4;
}

h1 {
  font-size: 1.6em;
  scroll-margin-top: $startLayout;
  color: $bleuciel;
  text-transform: uppercase;
  margin-bottom: 0;
}

h2 {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: $bleuvert;
}

h3 {
  font-size: 1.3rem;
  color: $bleuvert;
  text-transform: uppercase;
}

h4 {
  font-size: 1.2rem;
  color: $bleuvert;
}

h5 {
  font-size: 1.1rem;
  color: $bleuvert;
}

.mainContent {
  display: flex;
  justify-content: space-evenly;
  text-justify: distribute;
  text-align: justify;

  a {
    text-decoration: none;
    color: $bleumarine;
  }

  ul {
    list-style: none;
    vertical-align: middle;
  }

  .carousel li {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 28px;
  }

  li::before {
    align-self: flex-start;
    vertical-align: bottom;
    content: "\2022";
    font-size: 3rem;
    line-height: 25px;
    color: $bleuciel;
    padding: 10px;
    margin-left: -25px;
    text-shadow: -2px -2px 1px $gris;
  }

  .react-multi-carousel-list li::before {
    content: unset;
  }

  p {
    overflow-wrap: break-word;
  }

  .logo-partenaire {
    img {
      max-width: 100%;
    }
  }
}



.txt-gch-img-drt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-shrink: 1 !important;
  flex-wrap: wrap;
  gap: 2%;

  @media (max-width: 991px) {
    margin-left: 0;
    .txt-gch-img-drt-body {
      width: 100%;
      flex-shrink: 1;
    }
    .txt-gch-img-drt-img {
      flex-shrink: 0;
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {


    .txt-gch-img-drt-body {
      width: 59%;
    }

    .txt-gch-img-drt-img {
      width: 39%;
    }
  }

  .txt-gch-img-drt-img {
    justify-self: flex-end;
    max-height: 650px;
  }
}

.txt-fnd-gris {
  background-color: $grisclair;
  @media (max-width: 991px) {
    padding: 20px !important;
  }
  padding: 80px !important;
}

.paragraph-images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  img {
    max-width: 100%;
  }
}

.paragraph-documents {
  padding-left: 50px !important;
}

.paragraphTexteOnImage{
  padding: 50px;
  color: white;
  position: relative;
  z-index: -1;
}

.paragraphTexteOnImage::before{
  content: '';
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

}


.img-vignette {
  max-width: 100%;
}

.vignette-card {
  @media (max-width: 991px) {
    width: 80% !important;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 25px;
  overflow: hidden;
  padding: 25px !important;

  a {
    text-decoration: none;
  }

  .lien-vignette {
    text-align: center;
    padding: 10px;
    border-radius: 25px;
    margin: 20px;
    color: white;
    text-decoration: none;
    max-width: 100%;
  }
}

.btn-Bleu {
  background-color: $bleuciel;
}

.btn-Vert {
  background-color: $vert;
}

.btn-Rouge {
  background-color: $rouge;
}

.vignette-card.bordure-Bleu {
  border: 2px solid $bleuciel;
}

.vignette-card.bordure-Vert {
  border: 2px solid $vert;
}

.vignette-card.bordure-Rouge {
  border: 2px solid $rouge;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-justify {
  text-align: justify;
}

@media (min-width: 992px) {

.pdl-90{
  padding-left: 90px !important;
}

.pdr-90{
  padding-right: 90px !important;
}
}

@media (max-width: 1400px) {
  .mainContent{
    padding-left: 15px;
    padding-right: 15px;
  }

}