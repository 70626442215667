.pdfViewer {
  max-width: 100%;
  embed {
    height: 100vh;
    max-width: 100%;
  }
}

.link-newsletter{
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .pdfViewer {
    embed {
      height: 70vh;
    }
  }
}
