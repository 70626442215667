@import "variables";

.SideNav {
  // padding: 1em;
  flex-shrink: 1;

  width: 100%;
}

.SideBar {
  display: flex;
  flex-wrap: wrap;
  max-width: 318px;

  @media (min-width: 540px) {
    min-width: 350px;
  }

  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
}

.SideNavlist {
  @media (max-width: 992px) {
    display: none;
  }
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 2;
  position: sticky;
  top: $startLayout + 16px;
  align-self: flex-start;

  li {
    background: linear-gradient(90deg, $bleuvert 0%, $bleuciel 100%);
    text-align: left;
    @media (max-width: 540px) {
      padding: 0.6em;
    }
    padding: 1rem;
    border: 1px solid white;
  }

  .SidenavTitle {
    background: linear-gradient(90deg, $bleuvert 0%, $bleuciel 100%);
    text-align: center;
    padding: 1rem;
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }

  li:hover {
    background-color: $bleuciel;
  }

  a {
    text-decoration: none;
    // font-weight: bold;
    font-size: 0.9em;
    color: white;
  }
  a:visited {
    color: white;
  }
  a:hover,
  a:focus {
    color: white;
    font-weight: bold;
    font-size: 1em;
  }

  .is-active {
    font-weight: bold;
    font-size: 1em;
  }
}
