@import "variables";

.navbar {
  padding: 0;
  overflow-wrap: break-word;
}

.homepagelink {
  display: none;
}

@media screen and (min-width: 1200px) {
  .nav-link {
    padding: 0 !important;
  }
}

.navbar-toggler {
  border: 0 !important;
  color: transparent !important;
  background-color: transparent;
}

.navlink {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.navlink:hover {
  color: white;
}

.dropdown-item.active,
.dropdown-item:hover {
  background-color: initial !important;
}

.navMenu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  margin-top: 0;

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    height: $navbarHeight;
    display: flex;
    flex-shrink: 1;
    justify-content: space-between;
    align-items: stretch;
    @media screen and (max-width: 768px) {
      padding-top: inherit;
    }
  }

  .logolink {
    flex-shrink: 1;
    width: fit-content;
    position: relative;
    @media screen and (min-width: 1400px) {
      left: 135px;
    }
    z-index: 2;
    height: $navbarHeight;
  }

  .logo {
    height: 100%;
    width: auto;
  }

  .Nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 1;
    gap: 10px;
    z-index: 0;
    padding-right: 20px;
    height: 100%;

    a {
      color: white;
      text-decoration: none;
    }

    .active {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 1199px) {
    .Nav {
      width: 100%;
      flex-direction: column;
      padding: 0;
      text-align: center;

      .dropdown-item {
        white-space: normal;
      }

      .nav-item {
        width: 100%;
      }
    }

    #basic-navbar-nav {
      background: linear-gradient(90deg, $bleuvert 50%, $bleuciel 100%);
      flex-direction: column;
      z-index: 0;
    }
  }
  @media screen and (min-width: 1200px) {
    #basic-navbar-nav {
      display: flex;
      justify-content: flex-end;

      .nav-item {
        height: 100%;

        a {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  // .nav-item:hover .dropdown-menu {
  //   display: block;
  // }

  @media screen and (min-width: 1200px) {
    .nav-item:last-of-type .dropdown-menu {
      right: 0;
      left: auto;
    }

    .dropdown-menu a:hover {
      font-weight: bold;
      color: white;
    }
  }

  .dropdown-menu {
    background: linear-gradient(90deg, $bleuvert 50%, $bleuciel 100%);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0;
    right: -100%;
    left: -100%;
    @media screen and (max-width: 1199px) {
      position: relative;
      background: transparent;
      text-align: center;
      border-radius: 0;
      right: auto;
      left: auto;
    }
  }

  .navlink {
    height: 100%;
  }

  .nav-link {
    color: white;
  }

  .nav-link:hover,
  .nav-link:focus {
    color: white;
  }
}
