.partenaire-list {
  // padding-top: 100px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
}

.partenaire-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-partenaires {
  max-height: 80px;
}

@media (max-width: 992px) {
  .img-partenaires {
    max-width: 120px;
  }
}

.partenaire-title {
  text-align: center;
  text-transform: uppercase;
}
