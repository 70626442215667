//couleurs
$vert: #78be20;
$rouge: #e03c31;
$orange: #ea7600;
$bleuvert: #00b2a9;
$mauve: #c6007e;
$bleumarine: #1d428a;
$bleuciel: #41b6e6;
$gris: #85878b;
$grisclair: #f4f4f4;
$font-color: #757575;
$white : white;

//layout
$sidebarLeft: 30%;
$firstNavbarHeight: 42px;
$secondNavbarHeight: 75px;
$navbarHeight: $firstNavbarHeight + $secondNavbarHeight;
$logoHeight: $navbarHeight + 10px;
$startLayout: $logoHeight + 20px;
$startLayoutHomepage: $logoHeight - 10px;
$sidebarWidth: 339px;
