@import "variables";

.consultation + .voirplus {
  text-align: center;
}

.consultation {
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  justify-content: center;

  .date-consultation {
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    .jour {
      font-size: 3rem;
      line-height: 50px;
      color: $orange;
      font-weight: bold;
      text-align: center;
    }

    .mois-annee {
      min-width: 75px;
      text-transform: capitalize;
      font-weight: bold;
      color: $bleuvert;
      font-size: 1.5rem;
      line-height: 20px;
    }
  }

  .content {
    justify-content: flex-start;
    flex-shrink: 1;
    .titre {
      color: $bleuciel;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
    }

    .titre,
    .description p {
      margin: 0;
    }
  }
}

@media (max-width: 1200px) {
  .consultation {
    justify-content: center;

    .date-consultation {
      justify-content: center;
    }
    .mois-annee {
      align-items: center;
    }

    .content {
      align-items: center;

    }
  }
  
}
