@import "variables";

.headerBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $navbarHeight;
  position: fixed;
  align-items: center;
  background: $bleuvert;
  background: linear-gradient(90deg, $bleuvert 50%, $bleuciel 100%);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.logo {
  max-height: $secondNavbarHeight;
  background-color: white;
  border-radius: 0 0 20px 20px;
  // transform: translate(120px, -10px) scale(1.4);
  box-shadow: 0px 3px 6px #00000029;
}

.siteName {
  width: 10%;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  h2 {
    color: white;
    margin-bottom: 0;
    margin-top: 0;
  }
}
